//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContractDetail from "@/views/components/ContractDetail";
import {fetchSkipStepDetail, SkipStepSave} from '@/api/skipStep'
import { Message } from "element-ui";

export default {
  name: 'SkipStepDetail',
  components: {ContractDetail},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.usersOpened = from.fullPath.includes('SkipStep')
    })
  },
  data() {
    return {
      items: {
        contractId: '',
        curStep: ''
      },
      stepOptions: [{value: 4, label: 'เคสยอดเป็น 0 แต่มีการโอนรถ'}, {value: 5, label: 'เคสยอดเป็น 0 แต่ไม่มีการโอนรถ'}],
      isLoading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      contractParam: {
        contractId: this.$route.params.id
      },
      formData: {
        contractId: '',
				stepName: '',
        curStep: ''
      },
      formRules: {
        curStep: [
          { required: true, message: 'กรุณาเลือกขั้นตอน', trigger: 'change' }
        ],
      },
    }
  },
  computed: {
    contractDetail() {
      return this.items
    },
  },

  async mounted() {
    await this.getSkipStepDetail();
  },

  methods: {
    async getSkipStepDetail() {
      await fetchSkipStepDetail(this.contractParam).then(res => {
        const check = res.header
        const data = res.body
        if(check.error === 'N') {
          this.formData.contractId = data[0].contract_id;
					this.formData.stepName = data[0].step_name;
          this.items = data[0];
          this.items['curStep'] = null;
        }
      }).catch(error => {
        Message({
          message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          type: 'error',
          duration: 5 * 1000,
        })
        this.errorMsg = error
        console.log('error :', error)
      })
    },

    saveSkipStep() {
      this.$refs['detailForm'].validate((valid) => {
        if (valid) {
          if(this.formData.curStep !== '') {
            SkipStepSave(this.formData).then(res => {
              const check = res.header
              if (check.error === 'N') {
                Message({
                  message: 'บันทึกข้อมูลสำเร็จ',
                  type: 'success',
                  duration: 2 * 1000,
                  onClose: () => {
                    this.goBack()
                  }
                })
              }
            }).catch(error => {
              Message({
                message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
                type: 'error',
                duration: 2 * 1000,
              })
              this.errorMsg = error
              console.log(error)
            })
          } else {
            Message({
              message: 'กรุณาตรวจสอบข้อมูลให้ครบถ้วน',
              type: "warning",
              duration: 2 * 1000
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/skipStep'})
    }
  },
}
